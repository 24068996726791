/*
 * @Author: wuhuiwang 
 * @Date: 2024-06-Th 11:13:10 
 * @Last Modified by:   wuhuiwang 
 * @Last Modified time: 2024-06-Th 11:13:10 
 */

const routes = [
    // 三周年活动
    {
        path: 'activity/lift-lottery/:activityId',
        name: 'LiftLottery',
        component: () => import('credmex-new/app/webview/activity/lift-lottery'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: 'activity/lift-lottery/:activityId/history',
        name: 'LiftLotteryHistory',
        component: () => import('credmex-new/app/webview/activity/lift-lottery/history'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    // 7月summer vacation活动
    {
        path: 'activity/summer-vacation/:activityId',
        name: 'SummerVacation',
        component: () => import('credmex-new/app/webview/activity/summer-vacation'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: 'activity/summer-vacation/:activityId/history',
        name: 'SummerVacationHistory',
        component: () => import('credmex-new/app/webview/activity/summer-vacation/history'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    // 邀请活动
    {
        path: 'activity/invitation',
        name: 'Invitation',
        component: () => import('credmex-new/app/webview/activity/invitation'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: 'activity/invitation/detail',
        name: 'InvitationDetail',
        component: () => import('credmex-new/app/webview/activity/invitation/detail'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: 'activity/invitation/register/:code/:id',
        name: 'InvitationRegister',
        component: () => import('credmex-new/app/webview/activity/invitation/register'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true,
                withUserKey: true
            }
        }
    },
    // 返现活动
    {
        path: 'activity/cashback/:activityId',
        name: 'Cashback',
        component: () => import('credmex-new/app/webview/activity/cashback'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    }
];

export default routes;
