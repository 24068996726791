var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cm-popup" },
    [
      _c(
        "div",
        {
          staticClass: "select-item",
          on: {
            click: function ($event) {
              _vm.visible = !_vm.visible
            },
          },
        },
        [
          _c("div", { staticClass: "select-style" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.currentItem && _vm.currentItem.label) +
                "\n        "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "van-popup",
        {
          attrs: {
            position: "bottom",
            round: "",
            "close-on-click-overlay": _vm.closeOnClickOverlay,
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "title-close" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.title) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "close",
              attrs: { src: require("../img/close.svg") },
              on: {
                click: function ($event) {
                  return _vm.handleClickItem(_vm.currentItem)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm.columsData.length
            ? _c(
                "div",
                { staticClass: "content-box" },
                _vm._l(_vm.columsData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "box-item",
                      class: {
                        "box-item-active":
                          _vm.currentItem &&
                          _vm.currentItem.label === item.label,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleClickItem(item)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.label) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "content-box" }, [_c("cm-empty")], 1),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }