var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "reach-show",
          rawName: "v-reach-show",
          value: "elegant-in",
          expression: "'elegant-in'",
        },
      ],
      staticClass: "ck-footer",
    },
    [
      _c("div", { staticClass: "contact-info" }, [
        _c("div", { staticClass: "container info-wrapper" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "info-anchor" },
            [
              _c(
                "div",
                { staticClass: "anchor-item" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("footer.customerService")) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.emailList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "context-text text" },
                      [
                        _c("a", { attrs: { href: _vm.getEmailHref() } }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t(item.prefix)) +
                              " " +
                              _vm._s(item.email) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "context-text text" }, [
                    _c("a", { attrs: { href: _vm.telHref } }, [
                      _vm._v(_vm._s(_vm.$t("footer.tel")) + _vm._s(_vm.tel)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "context-text text" }, [
                    _c("a", { attrs: { href: "tel:525632298934" } }, [
                      _vm._v(_vm._s(_vm.$t("footer.wa"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "context-text text" }, [
                    _c(
                      "a",
                      { attrs: { href: "/term/CREDMEX_TERMS_AND_CONDITIONS" } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("footer.terms")) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "context-text text" }, [
                    _c(
                      "a",
                      { attrs: { href: "/term/CREDMEX_PRIVACY_POLICY" } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("footer.privacy")) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "context-text text",
                    domProps: { innerHTML: _vm._s(_vm.$t("footer.time")) },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm._l(_vm.$t("footer.anchorList"), function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "anchor-item" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.title) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(item.list, function (sm, si) {
                      return _c(
                        "div",
                        { key: si, staticClass: "context-text text" },
                        [
                          _c("a", { attrs: { href: sm.href } }, [
                            _vm._v(_vm._s(sm.title)),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container copy-right-wrapper" }, [
          _c("div", { staticClass: "copy-right" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("footer.copyRight", [new Date().getFullYear()])) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "share" },
            _vm._l(_vm.shareList, function (item, index) {
              return _c("img", {
                key: index,
                attrs: { src: item.imgSrc },
                on: {
                  click: function ($event) {
                    return _vm.clickShare(item.url)
                  },
                },
              })
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: require("./img/credmex-logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }