/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:02:50
 */

import Vue, {VueRouter, createVueRouter} from 'ssr-common/vue';

import routes from './routes';

Vue.use(VueRouter);

export const createRouter = () => createVueRouter({routes});
