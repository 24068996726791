/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-06-Th 07:07:18
 */

import BusinessEvents from 'credmex-new/common/constant/business-events';

import RoutesActivity from './routes-activity';

export default [
    {
        path: '/',
        name: 'Index',
        component: () => import('credmex-new/app/index-new'),
        children: [
            {
                path: '',
                name: 'Borrow',
                component: () => import('credmex-new/app/index-new/borrow')
            },
            {
                path: 'borrow',
                name: 'Borrow',
                component: () => import('credmex-new/app/index-new/borrow')
            },
            {
                path: 'faq',
                name: 'Faq',
                component: () => import('credmex-new/app/index-new/faq')
            },
            {
                path: 'term/:key',
                name: 'term',
                component: () => import('credmex-new/app/index-new/term')
            },
            {
                path: 'about-us',
                name: 'AboutUs',
                component: () => import('credmex-new/app/index/about-us')
            },
            {
                path: 'protect',
                name: 'Protect',
                component: () => import('credmex-new/app/index-new/protect')
            },
            {
                path: 'blog',
                name: 'Blog',
                component: () => import('credmex-new/app/index-new/blog')
            },
            {
                path: 'blog/detail/:blogPath',
                name: 'BlogDetail',
                component: () => import('credmex-new/app/index-new/blog-detail')
            }
        ]
    },
    {
        path: '/webview',
        component: () => import('credmex-new/app/webview'),
        children: [
            {
                path: 'test-bridge',
                name: 'testBridge',
                component: () => import('ssr-common/vue/component/test-bridge')
            },
            {
                path: 'help-center',
                name: 'helpCenter',
                component: () => import('credmex-new/app/webview/help-center'),
                redirect: {path: '/webview/help-center/loan'},
                children: [
                    {
                        name: 'question',
                        path: ':type',
                        component: () => import('easycash/app/webview/help-center/question')
                    }
                ]
            },
            {
                path: 'home',
                name: 'home',
                component: () => import('credmex-new/app/webview/home')
            },
            {
                path: 'home-new',
                name: 'homeNew',
                component: () => import('credmex-new/app/webview/home-new')
            },
            {
                path: 'about-us',
                name: 'webviewAboutUs',
                component: () => import('credmex-new/app/webview/about-us')
            },
            {
                path: 'agreement',
                name: 'agreement',
                component: () => import('easycash/app/webview/agreement')
            },
            {
                path: 'agreement/:key',
                name: 'agreementKey',
                component: () => import('easycash/app/webview/agreement')
            },
            {
                path: 'static-agreement/:key',
                name: 'staticAgreement',
                component: () => import('easycash/app/webview/static-agreement')
            },
            {
                path: 'static-text/:key',
                name: 'staticText',
                component: () => import('easycash/app/webview/static-text')
            },
            {
                path: 'message',
                name: 'Message',
                component: () => import('credmex-new/app/webview/message-center/message')
            },
            {
                path: 'message/:id',
                name: 'MessageDetail',
                component: () => import('credmex-new/app/webview/message-center/message-detail')
            },
            {
                path: 'spider/operator',
                name: 'operator',
                component: () => import('credmex-new/app/webview/spider/operator'),
                meta: {
                    titleKey: 'webview.title.operator'
                }
            },
            {
                path: 'reloan-accounting',
                name: 'ReloanAccounting',
                component: () => import('easycash/app/webview/reloan-accounting')
            },
            {
                path: 'credit-risk-score',
                name: 'creditRiskScore',
                component: () => import('credmex-new/app/webview/credit-risk-score')
            },
            {
                path: 'repay/:method',
                name: 'repay',
                component: () => import('credmex-new/app/webview/repay')
            },
            {
                path: 'repay-spei',
                name: 'repaySpei',
                component: () => import('credmex-new/app/webview/repay-spei')
            },
            {
                path: 'activity/lottery/:activityId',
                name: 'ActivityLottery',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.ActivityLotteryPageEnter
                    }
                },
                component: () => import('olacred/app/webview/activity/lottery')
            },
            {
                path: 'activity/record/:activityId',
                name: 'ActivityRecord',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.ActivityLotteryRecordPageEnter
                    }
                },
                component: () => import('olacred/app/webview/activity/record')
            },
            {
                path: 'compare',
                name: 'Compare',
                component: () => import('credmex-new/app/webview/compare')
            },
            {
                path: 'logout',
                name: 'Logout',
                component: () => import('easycash/app/webview/logout-page')
            },
            {
                path: 'sos',
                name: 'sos',
                component: () => import('credmex-new/app/webview/sos')
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import('credmex-new/app/webview/contact')
            },
            {
                path: 'spot',
                name: 'spot',
                component: () => import('credmex-new/app/webview/spot')
            },
            {
                path: 'anti-fraud-long',
                name: 'antiFraudLong',
                component: () => import('credmex-new/app/webview/anti-fraud-long')
            },
            {
                path: 'anti-fraud',
                name: 'antiFraud',
                component: () => import('credmex-new/app/webview/anti-fraud'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.AntiFraudPageEnter
                    }
                }
            },
            {
                path: 'social-insurance',
                name: 'social-insurance',
                component: () => import('credmex-new/app/webview/social-insurance')
            },
            {
                path: 'questionnaire',
                name: 'questionnaire',
                component: () => import('credmex-new/app/webview/questionnaire'),
                meta: {
                    noEdit: true
                }
            },
            {
                path: 'payroll',
                name: 'payroll',
                component: () => import('credmex-new/app/webview/payroll')
            },
            {
                path: 'qrcode',
                name: 'qrcode',
                component: () => import('credmex-new/app/webview/payroll/qrcode')
            },
            {
                path: 'credit/card',
                name: 'CreditCard',
                component: () => import('credmex-new/app/webview/credit/card')
            },
            {
                path: 'credit/driver',
                name: 'CreditDriver',
                component: () => import('credmex-new/app/webview/credit/driver')
            },
            {
                path: 'credit/vehicle',
                name: 'CreditVehicle',
                component: () => import('credmex-new/app/webview/credit/vehicle')
            },
            {
                path: 'credit/shop',
                name: 'CreditShop',
                component: () => import('credmex-new/app/webview/credit/shop')
            },
            {
                path: 'credit/bank',
                name: 'CreditBank',
                component: () => import('credmex-new/app/webview/credit/bank')
            },
            {
                path: 'loan-market',
                name: 'loan-market',
                component: () => import('credmex-new/app/webview/loan-market')
            },
            {
                path: 'loan-market-detail/:id',
                name: 'loan-market-detail',
                component: () => import('credmex-new/app/webview/loan-market/detail')
            },
            {
                path: 'delete-account',
                name: 'deleteAccount',
                component: () => import('credmex-new/app/webview/unsubscribe/delete-account')
            },
            {
                path: 'unsubscribe',
                name: 'unsubscribe',
                component: () => import('credmex-new/app/webview/unsubscribe')
            },
            {
                path: 'unsubscribe/reason',
                name: 'unsubscribeReason',
                component: () => import('credmex-new/app/webview/unsubscribe/reason')
            },
            {
                path: 'unsubscribe/otp',
                name: 'unsubscribeOtp',
                component: () => import('credmex-new/app/webview/unsubscribe/otp')
            },
            {
                path: 'unsubscribe/confirm',
                name: 'unsubscribeConfirm',
                component: () => import('credmex-new/app/webview/unsubscribe/confirm')
            },
            {
                path: 'issues',
                name: 'issues',
                component: () => import('credmex-new/app/webview/issues')
            },
            {
                path: 'edit-old-mobile',
                name: 'editOldMobile',
                component: () => import('credmex-new/app/webview/edit-mobile/old-mobile')
            },
            {
                path: 'edit-new-mobile',
                name: 'editNewMobile',
                component: () => import('credmex-new/app/webview/edit-mobile/new-mobile')
            },
            {
                path: 'edit-mobile-result',
                name: 'editMobileResult',
                component: () => import('credmex-new/app/webview/edit-mobile/result')
            },
            {
                path: 'prohibit-repetition',
                name: 'prohibitRepetition',
                component: () => import('credmex-new/app/webview/edit-mobile/prohibit-repetition')
            },
            {
                path: 'guide-page',
                name: 'guidePage',
                component: () => import('credmex-new/app/webview/guide-page/index')
            },
            {
                path: 'protocol-set',
                name: 'protocolSet',
                component: () => import('credmex-new/app/webview/protocol-set/index')
            },
            {
                path: 'balance',
                name: 'BalanceAccount',
                component: () => import('credmex-new/app/webview/balance')
            },
            {
                path: 'balance/withdrawal',
                name: 'BalanceWithdrawal',
                component: () => import('credmex-new/app/webview/balance/withdrawal'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'pdf-preview',
                name: 'PdfPreview',
                component: () => import('credmex-new/app/webview/pdf-preview')
            },
            ...RoutesActivity
        ]
    },
    {
        path: '/native/*',
        name: 'deepLinkLandingPage',
        component: () => import('credmex-new/app/webview/redirect')
    },
    {
        path: '*',
        component: () => import('credmex-new/app/not-found')
    }
];
