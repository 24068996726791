var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty" }, [
    _c("img", {
      staticClass: "empty-img",
      attrs: { src: require("../img/empty.png") },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "empty-text" }, [
      _vm._v("\n        " + _vm._s(_vm.emptyText) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }