<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-04-30 17:20:56.295 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-12 16:23:39 -->

<template>
    <div class="ck-header-wrapper">
        <div
            class="ck-header"
            :style="{
                boxShadow: !(isMobile && $route.name === 'Faq' && !isMenuOpen) ?
                    `0px ${showSubTab ? '96px' : '6px'} 80px 0px rgba(185,200,219,0.5)` : 'none'
            }"
        >
            <div class="ck-header-main">
                <div class="container">
                    <div
                        class="nav-icon d-md-none"
                        :class="{open: isMenuOpen}"
                        @click="isMenuOpen = !isMenuOpen"
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                    <div class="logo">
                        <router-link :to="{name: 'Borrow'}">
                            <img
                                src="./img/logo.png"
                                alt=""
                            >
                        </router-link>
                    </div>
                    <div
                        class="lang-box d-md-none"
                        @click="switchLanguage"
                    >
                        <span
                            class="lang"
                            :class="{'lang-active': $i18n.locale === 'es'}"
                        >ES</span>
                        <span class="line" />
                        <span
                            class="lang"
                            :class="{'lang-active': $i18n.locale === 'en'}"
                        >EN</span>
                    </div>
                    <div
                        v-show="(mounted && !isMobile)"
                        class="btn-group d-none d-md-flex"
                    >
                        <div
                            v-for="tab in tabList"
                            :key="tab.name"
                            :class="{active: routerName === tab.name, sos: tab.name === 'SOS'}"
                            class="btn"
                        >
                            <div
                                class="btn-title"
                                @click="handleClickTitle(tab)"
                                @mouseover="handleShowTab(tab)"
                                @mouseout="handleOutTab(tab)"
                            >
                                <span>{{ $t(tab.title) }}</span>
                                <span v-if="tab.subList.length">
                                    <img
                                        v-if="tab.name === 'SOS'"
                                        src="./img/sos-up.png"
                                        class="up-icon"
                                        :class="{'close': tab.showSubTab}"
                                    >
                                    <img
                                        v-else
                                        src="./img/up.png"
                                        class="up-icon"
                                        :class="{'close': tab.showSubTab}"
                                    >
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-show="mounted"
                        class="btn-download d-none d-md-block"
                        @click="switchLanguage"
                    >
                        <img :src="currentLangImg">
                    </div>
                </div>
            </div>
            <div
                id="anchor-open"
                class="ck-header-anchor"
                @mouseover="handleShowSubTab"
                @mouseleave="handleOutSubTab"
            >
                <div
                    v-if="currentTab && currentTab.subList"
                    class="anchor-box"
                >
                    <div
                        v-for="item in $t(currentTab.subList)"
                        :key="item.title"
                    >
                        <div
                            class="context-text anchor-text"
                            @click="handleAnchor(item.href)"
                        >
                            {{ item.title }}
                        </div>
                    </div>
                </div>
            </div>

            <download-modal
                :visible="modalVisible"
                @close="modalVisible = false"
            />
        </div>
        <div
            v-if="isMenuOpen"
            class="ck-collapse"
        >
            <van-collapse
                v-model="activeName"
                accordion
            >
                <van-collapse-item
                    v-for="tab in tabList"
                    :key="tab.name"
                    :name="tab.name"
                    :class="{sos: tab.name === 'SOS'}"
                >
                    <template #title>
                        <div @click="handleRouter(tab)">
                            {{ $t(tab.title) }}
                        </div>
                    </template>
                    <div
                        v-for="item in $t(tab.subList)"
                        :key="item.title"
                        class="co-item"
                        @click="handleAnchor(item.href)"
                    >
                        {{ item.title }}
                    </div>
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
</template>

<script type="text/babel">
import {Collapse, CollapseItem} from 'vant';

import EnImg from './img/en.png';
import EsImg from './img/es.png';

export default {
    name: 'CkHeader',

    components: {
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem
    },

    data() {
        return {
            tabList: [
                {name: 'Borrow', title: 'borrow.title', showSubTab: false, subList: 'footer.anchorList[0].list'},
                {name: 'Faq', title: 'faq.title', showSubTab: false, subList: 'footer.anchorList[1].list'},
                {name: 'SOS', title: 'sos.title', showSubTab: false, subList: 'footer.anchorList[2].list'}
            ],
            mounted: false,
            isMenuOpen: false,
            isMobile: true,
            currentTab: null,
            showSubTab: false,
            modalVisible: false,
            activeName: 'Borrow',
            subTabEl: '',
            EsImg,
            EnImg
        };
    },

    computed: {
        routerName() {
            return this.$route.name;
        },

        currentLang() {
            return this.$i18n.locale;
        },

        currentLangImg() {
            return this.currentLang === 'en' ? EsImg : EnImg;
        }
    },

    watch: {
        isMenuOpen(val) {
            if (val) {
                this.activeName = 'Borrow';
            }
        }
    },

    mounted() {
        this.mounted = true;
        if (window.innerWidth > 576) this.isMobile = false;
        this.subTabEl = document.querySelector('.ck-header-anchor');
    },

    methods: {
        handleShowTab(tab) {
            this.subTabEl.style.height = '90px';
            this.subTabEl.style.borderTop = '1px solid #E0E0E0';
            if (tab) {
                this.currentTab = tab;
                this.showSubTab = true;
                this.tabList.forEach(item => {
                    if (item.name === tab.name) {
                        item.showSubTab = true;
                    } else {
                        item.showSubTab = false;
                    }
                });
            }
        },

        handleOutTab() {
            const menu = document.querySelector('.btn-group');
            const menuX = menu.offsetLeft + menu.offsetWidth;
            const menuY = menu.offsetTop + menu.offsetHeight;
            const {event} = window;
            const mouseX = event.clientX;
            const mouseY = event.clientY;
            if (mouseX < menuX || mouseX > menuX || mouseY < menuY || mouseY > menuY) {
                this.subTabEl.style.borderTop = 'none';
                this.subTabEl.style.height = '0px';
                this.showSubTab = false;
            }
        },

        handleShowSubTab() {
            if (!this.currentTab) return;
            this.subTabEl.style.height = '90px';
            this.subTabEl.style.borderTop = '1px solid #E0E0E0';
            this.showSubTab = true;
        },

        handleOutSubTab() {
            this.subTabEl.style.borderTop = 'none';
            this.subTabEl.style.height = '0px';
            this.showSubTab = false;
            this.currentTab = null;
            this.tabList.forEach(item => {
                item.showSubTab = false;
            });
        },

        handleClickTitle(tab) {
            if (this.$route.name !== tab.name) {
                this.$router.push({name: tab.name});
            }
        },

        handleAnchor(href) {
            if (this.isMobile) {
                this.isMenuOpen = !this.isMenuOpen;
            }

            this.handleOutSubTab();
            this.$router.push({path: href});
        },

        handleRouter(tab) {
            this.handleClickTitle(tab);
            if (this.isMobile) {
                this.isMenuOpen = !this.isMenuOpen;
            }
        },

        switchLanguage() {
            const lang = this.currentLang === 'es' ? 'en' : 'es';
            this.$i18n.locale = lang;
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.ck-header-wrapper {
    position: sticky;
    top: 0;
    z-index: 15;
}

.ck-header {
    background: #fefefe;
    position: relative;
    z-index: 20;

    .ck-header-main {
        height: 90px;
        display: flex;
        align-items: center;

        @media screen and (max-width: $screen-md) {
            height: 76px;
            padding: 25px 30px;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;

            @media screen and (max-width: $screen-md) {
                display: block;
            }

            .logo {
                width: 180px;

                @media screen and (max-width: $screen-md) {
                    width: 120px;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }

            .lang-box {
                display: inline-flex;
                align-items: center;
                position: absolute;
                right: 25px;

                .lang {
                    font-size: 14px;
                    font-family: Lexend-Regular, Lexend;
                    font-weight: 400;
                    color: #6e767e;
                    line-height: 18px;

                    &-active {
                        font-family: Lexend-Bold, Lexend;
                        font-weight: bold;
                    }
                }

                .line {
                    width: 1px;
                    height: 10px;
                    background: #e0e0e0;
                    margin: 0 3px;
                }
            }

            .btn-group {
                display: flex;
                align-items: center;

                .btn {
                    position: relative;
                    font-size: 16px;
                    color: $title-color;
                    font-weight: 400;
                    height: 90px;
                    display: flex;
                    align-items: center;

                    &.active {
                        font-weight: 800;
                        font-family: Lexend-ExtraBold, Lexend;
                    }

                    &.sos {
                        color: #ff4830;
                    }

                    .btn-title {
                        height: 90px;
                        padding: 0 20px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .up-icon {
                            transition: all 0.2s;
                            width: 12px;
                            margin-left: 3px;

                            &.close {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            .btn-download {
                img {
                    width: 59px;
                    height: 32px;
                    cursor: pointer;
                }
            }

            .nav-icon {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 16px;
                transform: rotate(0deg);
                transition: 0.5s ease-in-out;
                cursor: pointer;

                &-scroll {
                    span {
                        background: #333 !important;
                    }
                }

                &.open {
                    span {
                        background: #2b4057;

                        &:nth-child(1) {
                            top: 10px;
                            transform: rotate(135deg);
                        }

                        &:nth-child(2) {
                            opacity: 0;
                            left: -40px;
                        }

                        &:nth-child(3) {
                            top: 10px;
                            transform: rotate(-135deg);
                        }
                    }
                }

                span {
                    display: block;
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    background: #333;
                    border-radius: 2px;
                    opacity: 1;
                    left: 0;
                    transform: rotate(0deg);
                    transition: 0.25s ease-in-out;

                    &:nth-child(1) {
                        top: 0;
                    }

                    &:nth-child(2) {
                        top: 7px;
                    }

                    &:nth-child(3) {
                        top: 14px;
                    }
                }
            }
        }
    }

    .ck-header-anchor {
        position: absolute;
        width: 100%;
        height: 0;
        overflow: hidden;
        background-color: #fefefe;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: Lexend-Bold, Lexend;
        font-weight: bold;
        color: #2b4057;
        top: 90px;
        transition: height 0.2s linear;

        .anchor-box {
            display: flex;

            .anchor-text {
                padding: 0 20px;
                color: #2b4057;
                line-height: 20px;
                cursor: pointer;
            }
        }
    }
}

.ck-collapse {
    width: 100%;
    height: calc(100vh - 76px);
    background-color: #fefefe;
    z-index: 15;
    transition: all 0.2s ease-in-out;

    ::v-deep .van-cell {
        background-color: #fefefe;
        padding: 26px 25px;

        &::after {
            border-bottom: none;
        }
    }

    ::v-deep .van-cell__title {
        font-size: 16px;
        font-family: Lexend-ExtraBold, Lexend;
        font-weight: 800;
        color: #2b4057;
        line-height: 20px;
    }

    .sos {
        ::v-deep .van-cell__title {
            color: #ff4830;
        }
    }

    ::v-deep .van-collapse-item__content {
        padding: 0 25px;

        .co-item {
            padding: 0 0 30px;
            font-size: 14px;
            font-family: Lexend-Regular, Lexend;
            font-weight: 400;
            color: #6e767e;
            line-height: 18px;
        }
    }

    ::v-deep .van-collapse-item--border::after {
        left: 25px;
        right: 25px;
        border-top: 1px solid #e0e0e0;
    }

    ::v-deep .van-cell__right-icon {
        width: 20%;
        text-align: right;
    }

    ::v-deep .van-collapse-item__title .van-cell__right-icon::before {
        transform: rotate(0deg);
    }

    ::v-deep .van-collapse-item__title--expanded .van-cell__right-icon::before {
        transform: rotate(-90deg);
    }

    ::v-deep .van-hairline--top-bottom::after,
    ::v-deep .van-hairline-unset--top-bottom::after {
        border-width: 0;
    }
}
</style>
