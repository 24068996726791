var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ck-header-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "ck-header",
        style: {
          boxShadow: !(
            _vm.isMobile &&
            _vm.$route.name === "Faq" &&
            !_vm.isMenuOpen
          )
            ? "0px " +
              (_vm.showSubTab ? "96px" : "6px") +
              " 80px 0px rgba(185,200,219,0.5)"
            : "none",
        },
      },
      [
        _c("div", { staticClass: "ck-header-main" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "nav-icon d-md-none",
                class: { open: _vm.isMenuOpen },
                on: {
                  click: function ($event) {
                    _vm.isMenuOpen = !_vm.isMenuOpen
                  },
                },
              },
              [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "logo" },
              [
                _c("router-link", { attrs: { to: { name: "Borrow" } } }, [
                  _c("img", {
                    attrs: { src: require("./img/logo.png"), alt: "" },
                  }),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "lang-box d-md-none",
                on: { click: _vm.switchLanguage },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "lang",
                    class: { "lang-active": _vm.$i18n.locale === "es" },
                  },
                  [_vm._v("ES")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "line" }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "lang",
                    class: { "lang-active": _vm.$i18n.locale === "en" },
                  },
                  [_vm._v("EN")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mounted && !_vm.isMobile,
                    expression: "(mounted && !isMobile)",
                  },
                ],
                staticClass: "btn-group d-none d-md-flex",
              },
              _vm._l(_vm.tabList, function (tab) {
                return _c(
                  "div",
                  {
                    key: tab.name,
                    staticClass: "btn",
                    class: {
                      active: _vm.routerName === tab.name,
                      sos: tab.name === "SOS",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "btn-title",
                        on: {
                          click: function ($event) {
                            return _vm.handleClickTitle(tab)
                          },
                          mouseover: function ($event) {
                            return _vm.handleShowTab(tab)
                          },
                          mouseout: function ($event) {
                            return _vm.handleOutTab(tab)
                          },
                        },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t(tab.title)))]),
                        _vm._v(" "),
                        tab.subList.length
                          ? _c("span", [
                              tab.name === "SOS"
                                ? _c("img", {
                                    staticClass: "up-icon",
                                    class: { close: tab.showSubTab },
                                    attrs: { src: require("./img/sos-up.png") },
                                  })
                                : _c("img", {
                                    staticClass: "up-icon",
                                    class: { close: tab.showSubTab },
                                    attrs: { src: require("./img/up.png") },
                                  }),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mounted,
                    expression: "mounted",
                  },
                ],
                staticClass: "btn-download d-none d-md-block",
                on: { click: _vm.switchLanguage },
              },
              [_c("img", { attrs: { src: _vm.currentLangImg } })]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "ck-header-anchor",
            attrs: { id: "anchor-open" },
            on: {
              mouseover: _vm.handleShowSubTab,
              mouseleave: _vm.handleOutSubTab,
            },
          },
          [
            _vm.currentTab && _vm.currentTab.subList
              ? _c(
                  "div",
                  { staticClass: "anchor-box" },
                  _vm._l(_vm.$t(_vm.currentTab.subList), function (item) {
                    return _c("div", { key: item.title }, [
                      _c(
                        "div",
                        {
                          staticClass: "context-text anchor-text",
                          on: {
                            click: function ($event) {
                              return _vm.handleAnchor(item.href)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.title) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("download-modal", {
          attrs: { visible: _vm.modalVisible },
          on: {
            close: function ($event) {
              _vm.modalVisible = false
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isMenuOpen
      ? _c(
          "div",
          { staticClass: "ck-collapse" },
          [
            _c(
              "van-collapse",
              {
                attrs: { accordion: "" },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              _vm._l(_vm.tabList, function (tab) {
                return _c(
                  "van-collapse-item",
                  {
                    key: tab.name,
                    class: { sos: tab.name === "SOS" },
                    attrs: { name: tab.name },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRouter(tab)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.$t(tab.title)) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    _vm._l(_vm.$t(tab.subList), function (item) {
                      return _c(
                        "div",
                        {
                          key: item.title,
                          staticClass: "co-item",
                          on: {
                            click: function ($event) {
                              return _vm.handleAnchor(item.href)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.title) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }