<!-- @author wuhuiwang -->
<!-- @email wuhuiwang@yangqianguan.com -->
<!-- @date 2023-4-7 14:28:47 -->
<!-- @desc index.vue -->
<!-- 墨西哥增信页面结构 -->

<template>
    <div
        v-if="mounted"
        class="credit-content"
    >
        <div
            v-for="(item, index) in creditInfo"
            :key="item.title + index"
            class="credit-item"
        >
            <div
                v-if="item.groupName"
                class="group-name"
            >
                {{ item.groupName }}
            </div>
            <cm-auth-input
                :ref="`cmAuthInput${index}`"
                v-model="item.value"
                :type="item.type"
                :label="item.title"
                :maxlength="item.maxlength"
                :format="item.format"
                :reg="item.reg"
                :clearable="true"
                autocomplete="off"
                :index="index"
                :use-cleave="{
                    blocks: item.blocks
                }"
                :colums-data="item.columsData"
                :errortip="item.errorTip"
                @toggle="toggleAllCollapse"
            >
                <template #before>
                    <slot name="before" />
                </template>
            </cm-auth-input>
        </div>
    </div>
</template>

<script type="text/babel">
/* eslint-disable no-unused-expressions */
import CmAuthInput from 'credmex-new/common/component/cm-auth-input';

export default {
    name: 'CmCredit',

    components: {
        CmAuthInput
    },

    props: {
        creditInfo: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            mounted: false
        };
    },

    mounted() {
        this.mounted = true;
        this.$nextTick(() => {
            if (this.$refs.cmAuthInput0 && this.$refs.cmAuthInput0[0]) {
                // h5默认进来全部关闭
                // this.$refs.cmAuthInput0[0].$refs?.collapseItem?.toggle();
                // this.$refs.cmAuthInput0[0].$refs?.input?.focus();
                // this.$refs.cmAuthInput0[0].inputFocus = true;
                // this.$refs.cmAuthInput0[0].activeName = this.creditInfo[0].title;
                // this.$refs.cmAuthInput0[0].inputState = 'edit';
                // this.$refs.cmAuthInput0[0].handleSelectCollapse();
            }
        });
    },

    methods: {
        toggleAllCollapse(idx) {
            if (idx === 'all') {
                this.creditInfo.forEach((item, index) => {
                    const currentInput = this.$refs[`cmAuthInput${index}`][0];
                    if (item.value && currentInput) {
                        currentInput.inputState = 'finish';
                        if (!currentInput.activeName) {
                            currentInput.$refs?.collapseItem?.toggle(true);
                        }
                    }
                });

                return;
            }

            this.creditInfo.forEach((item, index) => {
                // 只处理非点击项，如果处于展开状态则收起，如果处于收起状态则不做处理
                const currentInput = this.$refs[`cmAuthInput${index}`][0];
                if (index !== idx && currentInput && currentInput.activeName && !currentInput.inputFocus) {
                    if (item.value) {
                        currentInput.inputState = 'finish';
                    } else {
                        currentInput.inputState = 'init';
                        currentInput.$refs?.collapseItem?.toggle(false);
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.credit-item {
    margin-top: 0.12rem;

    .group-name {
        color: $base-black;
        font-family: Lexend;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.65;
        margin: 0.12rem 0;
    }
}
</style>
