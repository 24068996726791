<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-04-30 13:51:05.279 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-12 15:33:29 -->

<template>
    <div
        v-reach-show="'elegant-in'"
        class="ck-footer"
    >
        <div class="contact-info">
            <div class="container info-wrapper">
                <div class="logo">
                    <img src="./img/credmex-logo.png">
                </div>
                <div class="info-anchor">
                    <div class="anchor-item">
                        <div class="title">
                            {{ $t('footer.customerService') }}
                        </div>
                        <div
                            v-for="(item,index) in emailList"
                            :key="index"
                            class="context-text text"
                        >
                            <a :href="getEmailHref()">
                                {{ $t(item.prefix) }} {{ item.email }}
                            </a>
                        </div>
                        <div class="context-text text">
                            <a :href="telHref">{{ $t('footer.tel') }}{{ tel }}</a>
                        </div>
                        <div class="context-text text">
                            <a href="tel:525632298934">{{ $t('footer.wa') }}</a>
                        </div>
                        <div class="context-text text">
                            <a href="/term/CREDMEX_TERMS_AND_CONDITIONS">
                                {{ $t('footer.terms') }}
                            </a>
                        </div>
                        <div class="context-text text">
                            <a href="/term/CREDMEX_PRIVACY_POLICY">
                                {{ $t('footer.privacy') }}
                            </a>
                        </div>

                        <div
                            class="context-text text"
                            v-html="$t('footer.time')"
                        />
                    </div>
                    <div
                        v-for="(item, index) in $t('footer.anchorList')"
                        :key="index"
                        class="anchor-item"
                    >
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div
                            v-for="(sm, si) in item.list"
                            :key="si"
                            class="context-text text"
                        >
                            <a :href="sm.href">{{ sm.title }}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container copy-right-wrapper">
                <div class="copy-right">
                    {{ $t('footer.copyRight', [new Date().getFullYear()]) }}
                </div>
                <div class="share">
                    <img
                        v-for="(item, index) in shareList"
                        :key="index"
                        :src="item.imgSrc"
                        @click="clickShare(item.url)"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import Webview from 'easycash/common/resource/Webview';

import facebook from './img/facebook.png';
import ins from './img/ins.png';
import twitter from './img/twitter.png';
import whatsapp from './img/whatsapp.png';
import youtube from './img/youtube.png';

export default {
    name: 'CkFooter',

    data() {
        return {
            tel: null,
            emailList: [
                {
                    prefix: 'footer.contactoEmail',
                    email: 'contacto@credmex.mx'
                },
                {
                    prefix: 'footer.quejasEmail',
                    email: 'quejas@credmex.mx'
                },
                {
                    prefix: 'footer.sosEmail',
                    email: 'sos@credmex.mx'
                }
            ],
            anchorList: this.$t('footer.anchorList'),
            shareList: [
                {imgSrc: facebook, url: 'https://www.facebook.com/CredmexMexico'},
                {imgSrc: youtube, url: 'https://www.youtube.com/channel/UCefrb9CdYaVll9X-GshJLWA'},
                {imgSrc: ins, url: 'https://www.instagram.com/credmex001/'},
                {imgSrc: whatsapp, url: 'https://api.whatsapp.com/send?phone=525632298934'},
                {imgSrc: twitter, url: 'https://twitter.com/Credmex1'}
            ]
        };
    },

    computed: {
        telHref() {
            return `tel:${this.tel}`;
        }
    },

    mounted() {
        this.getContact();
    },

    methods: {
        getEmailHref(email) {
            return `mailto:${email}`;
        },

        async getContact() {
            const {data: {body: {telephone}}} = await Webview.getContact();
            this.tel = telephone;
        },

        clickShare(url) {
            window.open(url);
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.ck-footer {
    position: relative;
    background-color: #172432;

    .contact-info {
        .info-wrapper {
            padding: 80px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-bottom: 1px solid #999;

            @media screen and (max-width: $screen-md) {
                padding: 50px 25px 100px;
                flex-direction: column;
            }

            .logo {
                img {
                    width: 150px;
                    height: 25px;

                    @media screen and (max-width: $screen-md) {
                        width: 130px;
                        height: 19px;
                    }
                }
            }

            .info-anchor {
                display: flex;
                flex-wrap: wrap;

                @media screen and (max-width: $screen-md) {
                    justify-content: space-between;
                }

                .anchor-item {
                    margin-left: 60px;
                    max-width: 278px;

                    .title {
                        font-size: 18px;
                        font-family: Lexend-Bold, Lexend;
                        font-weight: bold;
                        color: #fff;
                        line-height: 23px;
                        margin-bottom: 15px;
                    }

                    .text {
                        color: #c2c8ce;
                        line-height: 30px;
                    }

                    @media screen and (max-width: $screen-md) {
                        margin-left: 0;
                        margin-top: 50px;
                        max-width: 100%;

                        &:first-child {
                            width: 100%;
                        }

                        .title {
                            @media screen and (max-width: $screen-md) {
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }

                        .text {
                            text-align: left;
                            font-size: 12px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }

        .copy-right-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 50px 30px;
            color: #c0c0c0;
            text-align: center;

            @media screen and (max-width: $screen-md) {
                display: flex;
                flex-direction: column;
                padding: 30px 10px;
                position: relative;
            }

            .copy-right {
                font-size: 14px;
                font-family: Lexend-Regular, Lexend;
                font-weight: 400;
                color: #fff;
                line-height: 18px;

                @media screen and (max-width: $screen-md) {
                    font-size: 10px;
                }
            }

            .share {
                margin: 10px;

                img {
                    width: 40px;
                    margin-left: 10px;
                    display: inline;
                    cursor: pointer;
                }

                @media screen and (max-width: $screen-md) {
                    position: absolute;
                    bottom: 78px;
                }
            }
        }
    }
}

</style>
