/*
 * @Author: chengyuzhang
 * @Date: 2022-02-10 19:35:44
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-08-30 15:17:16
 */

export default {
    ActivityLotteryPageEnter: {
        tag: 'ActivityLotteryPageEnter',
        businessEvent: 'Credmex_ActivityLotteryPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    ActivityLotteryRecordPageEnter: {
        tag: 'ActivityLotteryRecordPageEnter',
        businessEvent: 'Credmex_ActivityLotteryRecordPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    AntiFraudPageEnter: {
        tag: 'AntiFraudPageEnter',
        businessEvent: 'Credmex_AntiFraudPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    }
};
