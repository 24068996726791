var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-collapse",
    {
      class: [_vm.inputState],
      attrs: { accordion: true, border: false },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "van-collapse-item",
        {
          ref: "collapseItem",
          attrs: {
            name: _vm.$attrs.label,
            title: _vm.$attrs.label,
            disabled: "",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "self-title",
                      on: { click: _vm.changeCollapse },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$attrs.label) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "right-icon",
              fn: function () {
                return [_c("div", { staticClass: "icon-right" })]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm.inputState !== "finish"
            ? _c(
                "div",
                [
                  _vm.$attrs.type === "select"
                    ? _c(
                        "div",
                        [
                          _c("cm-auth-select", {
                            ref: "cmPopup",
                            attrs: {
                              title: _vm.$attrs.label,
                              "colums-data": _vm.$attrs["colums-data"],
                            },
                            on: { change: _vm.handlePopupChange },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "input-item",
                        _vm._b(
                          {
                            ref: "inputItem",
                            on: {
                              input: _vm.handleInput,
                              focus: _vm.handleFocus,
                              blur: _vm.handleBlur,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "before",
                                  fn: function () {
                                    return [
                                      _c("span", { staticClass: "before-dot" }),
                                      _vm._v(" "),
                                      _vm._t("before"),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "after",
                                  fn: function () {
                                    return [
                                      _vm.$attrs.clearable &&
                                      _vm.$attrs.value &&
                                      _vm.inputFocus
                                        ? _c("img", {
                                            staticClass: "clearable",
                                            attrs: {
                                              src: require("../img/input-close.svg"),
                                            },
                                            on: {
                                              mousedown: function ($event) {
                                                return _vm.handleClearable(
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          "input-item",
                          _vm.$attrs,
                          false
                        )
                      ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "finish-box",
                  on: { click: _vm.changeCollapse },
                },
                [
                  _c("div", { staticClass: "finish-value" }, [
                    _vm.$attrs.type === "select"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$attrs.value && _vm.$attrs.value.label)
                          ),
                        ])
                      : _c("span", [_vm._v(_vm._s(_vm.$attrs.value))]),
                  ]),
                  _vm._v(" "),
                  !_vm.isValidInput && _vm.$attrs.errortip
                    ? _c("div", { staticClass: "finish-error" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$attrs.errortip) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }