/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:02:54
 */

import Vuex, {Store} from 'vuex';

import Vue from 'ssr-common/vue';

import baseData from './module/base-data';

Vue.use(Vuex);

export const createStore = () => new Store({
    state: {},
    modules: {
        baseData
    }
});
