<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-10-31 11:06:37 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-27 11:44:20 -->

<template>
    <div class="cm-popup">
        <div
            class="select-item"
            @click="visible = !visible"
        >
            <div class="select-style">
                {{ currentItem && currentItem.label }}
            </div>
        </div>
        <van-popup
            v-model="visible"
            position="bottom"
            round
            :close-on-click-overlay="closeOnClickOverlay"
        >
            <div class="title-close">
                <div class="title">
                    {{ title }}
                </div>
                <img
                    class="close"
                    src="../img/close.svg"
                    @click="handleClickItem(currentItem)"
                >
            </div>
            <div
                v-if="columsData.length"
                class="content-box"
            >
                <div
                    v-for="(item, index) in columsData"
                    :key="index"
                    class="box-item"
                    :class="{'box-item-active': currentItem && currentItem.label === item.label}"
                    @click="handleClickItem(item)"
                >
                    {{ item.label }}
                </div>
            </div>
            <div
                v-else
                class="content-box"
            >
                <cm-empty />
            </div>
        </van-popup>
    </div>
</template>

<script>
import {Popup} from 'vant';

import CmEmpty from 'credmex-new/common/component/cm-empty.vue';

export default {
    name: 'CmAuthSelect',

    components: {
        [Popup.name]: Popup,
        CmEmpty
    },

    props: {
        closeOnClickOverlay: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: ''
        },

        columsData: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            visible: false,
            currentItem: ''
        };
    },

    methods: {
        handleClickItem(item) {
            this.currentItem = item;
            this.visible = false;
            this.$emit('change', item || '');
        }
    }
};
</script>

<style lang="scss" scoped>
.cm-popup {
    font-family: Lexend;

    .select-item {
        padding: 0.16rem;

        .select-style {
            height: 0.27rem;
            border-bottom: 1px solid $black-45-l;
            color: $base-black;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 1.65;
            overflow: hidden;
            white-space: nowrap;

            &::before {
                display: inline-block;
                content: "";
                width: 0.04rem;
                height: 0.04rem;
                margin-right: 0.1rem;
                border-radius: 50%;
                background-color: $black-75-l;
            }
        }
    }

    .title-close {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.16rem;
        border-bottom: 1px solid $black-92-l;

        .title {
            color: $black-15-l;
            font-size: 0.16rem;
            font-weight: 700;
            line-height: 1.65; /* 26.4px */
        }

        .close {
            width: 0.24rem;
            height: 0.24rem;
            margin-left: 0.16rem;
        }
    }

    .content-box {
        padding: 0 0.16rem 0.24rem;

        .box-item {
            padding: 0.125rem 0;
            border-bottom: 1px solid $black-92-l;
            color: $black-30-l;
            font-size: 0.14rem;
            font-weight: 400;
            line-height: 1.65;

            &:last-child {
                border-bottom: none;
            }

            &-active {
                color: $primary-purple;
                font-weight: 700;
                line-height: 1.45;
            }
        }

        .empty {
            margin-top: 0.3rem;
        }
    }
}
</style>
