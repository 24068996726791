/**
 * @Author: chengyuzhang
 * @Date: 2020-04-13 16:38:27.247
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-10-10 16:04:18
 */

import EcImage from 'ssr-common/vue/component/ec-image';

import CkFooter from './ck-footer';
import CkHeader from './ck-header';
import CmCredit from './cm-credit/index.vue';
import DownloadModal from './download';

export default {

    install(Vue) {
        Vue.component('ec-image', EcImage);

        Vue.component('ck-header', CkHeader);
        Vue.component('ck-footer', CkFooter);
        Vue.component('download-modal', DownloadModal);
        Vue.component('cm-credit', CmCredit);
    }

};
