var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ec-overlay",
    { staticClass: "download-modal", attrs: { visible: _vm.visible } },
    [
      _c("div", { staticClass: "overlay-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "close",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_c("img", { attrs: { src: require("./img/close.svg") } })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "context-title download-title" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("borrow.download.title")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "download-content" }, [
          _c("div", { staticClass: "row content-row" }, [
            _c("div", { staticClass: "col-12 content-col" }, [
              _c("div", [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: require("./img/google-logo.png") },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "code" }, [
                _c("img", { attrs: { src: require("./img/google-code.png") } }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "store",
                  on: {
                    click: function ($event) {
                      return _vm.handleDownload("google")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "website",
                    attrs: { src: require("./img/website.png") },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "store-logo",
                    attrs: { src: require("./img/google-store.png") },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 content-col" }, [
              _c("div", [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: require("./img/app-logo.png") },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "code" }, [
                _c("img", { attrs: { src: require("./img/app-code.png") } }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "store",
                  on: {
                    click: function ($event) {
                      return _vm.handleDownload("apple")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "website",
                    attrs: { src: require("./img/website.png") },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "store-logo",
                    attrs: { src: require("./img/app-store.png") },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }