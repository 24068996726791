<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-10-24 15:47:35 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-27 11:54:39 -->

<template>
    <van-collapse
        v-model="activeName"
        :class="[inputState]"
        :accordion="true"
        :border="false"
    >
        <van-collapse-item
            ref="collapseItem"
            :name="$attrs.label"
            :title="$attrs.label"
            disabled
        >
            <template #title>
                <div
                    class="self-title"
                    @click="changeCollapse"
                >
                    {{ $attrs.label }}
                </div>
            </template>
            <template #right-icon>
                <div class="icon-right" />
            </template>
            <div v-if="inputState !== 'finish'">
                <div v-if="$attrs.type === 'select'">
                    <cm-auth-select
                        ref="cmPopup"
                        :title="$attrs.label"
                        :colums-data="$attrs['colums-data']"
                        @change="handlePopupChange"
                    />
                </div>
                <input-item
                    v-else
                    ref="inputItem"
                    v-bind="$attrs"
                    @input="handleInput"
                    @focus="handleFocus"
                    @blur="handleBlur"
                >
                    <template #before>
                        <span class="before-dot" />
                        <slot name="before" />
                    </template>
                    <template #after>
                        <img
                            v-if="$attrs.clearable && $attrs.value && inputFocus"
                            class="clearable"
                            src="../img/input-close.svg"
                            @mousedown="handleClearable($event)"
                        >
                    </template>
                </input-item>
            </div>
            <div
                v-else
                class="finish-box"
                @click="changeCollapse"
            >
                <div class="finish-value">
                    <span v-if="$attrs.type === 'select'">{{ $attrs.value && $attrs.value.label }}</span>
                    <span v-else>{{ $attrs.value }}</span>
                </div>
                <div
                    v-if="!isValidInput && $attrs.errortip"
                    class="finish-error"
                >
                    {{ $attrs.errortip }}
                </div>
            </div>
        </van-collapse-item>
    </van-collapse>
</template>

<script>
/* eslint-disable no-unused-expressions */
import {Collapse, CollapseItem} from 'vant';

import ua from 'ssr-common/util/webview/ua-parsed';

import InputItem from 'easycash/common/component/ec-form/input-item';

import CmAuthSelect from './cm-auth-select.vue';

export default {
    name: 'CmAuthInput',

    components: {
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        InputItem,
        CmAuthSelect
    },

    data() {
        return {
            activeName: '',
            inputFocus: false,
            inputState: 'init', // init edit finish
            windowHeight: ''
        };
    },

    computed: {
        isValidInput() {
            return new RegExp(this.$attrs.reg).test(this.$attrs.value);
        }
    },

    watch: {
        inputState(val) {
            if (val === 'edit') {
                this.$nextTick(() => {
                    this.$refs.inputItem?.$refs?.input?.focus();
                    if (ua.isIOS) {
                        const scrollTop = this.$refs.collapseItem?.$el?.offsetTop - 100;
                        document.documentElement.scrollTop = scrollTop;
                    }
                });
            }
        }
    },

    mounted() {
        this.windowHeight = window.innerHeight;
        window.addEventListener('resize', () => {
            if (ua.isAndroid) {
                const keyboardUp = window.innerHeight < this.windowHeight;
                !keyboardUp && this.$refs.inputItem?.$refs?.input?.blur();
            }
        });
    },

    methods: {
        handleInput(val) {
            this.$attrs.value = val;
            this.$emit('input', val);
        },

        handleFocus() {
            setTimeout(() => {
                this.inputFocus = true;
            }, 0);
        },

        handleBlur() {
            setTimeout(() => {
                if (!this.$attrs.value) {
                    this.$refs.collapseItem?.toggle(!this.activeName);
                }

                this.inputState = this.$attrs.value ? 'finish' : 'init';
                this.inputFocus = false;
            });
        },

        handleClearable(event) {
            event.preventDefault();
            this.$refs.inputItem?.$refs?.input?.focus();
            this.$attrs.value = '';
            this.$emit('input', '');
        },

        changeCollapse() {
            this.$emit('toggle', this.$attrs.index);
            if (!this.$attrs.value && !this.inputFocus) {
                this.inputState = 'init';
                this.$refs.collapseItem?.toggle(!!this.activeName);
                if (this.activeName) {
                    this.inputState = 'edit';
                    this.handleSelectCollapse();

                    return;
                }
            }

            if (this.inputState === 'edit' && this.$attrs.value) {
                this.inputState = 'finish';

                return;
            }

            if (this.inputState === 'finish') {
                this.inputState = 'edit';
                this.handleSelectCollapse();
            }
        },

        handleSelectCollapse() {
            this.$nextTick(() => {
                if (this.$attrs.type === 'select' && this.$refs.cmPopup) {
                    this.$refs.cmPopup.visible = true;
                    this.$refs.cmPopup.currentItem = this.$attrs['colums-data']?.filter(item => item.value === this.$attrs.value?.value)?.[0];
                }
            });
        },

        handlePopupChange(val) {
            this.$emit('input', val);
            this.inputState = val ? 'finish' : 'init';
            !val && this.$refs.collapseItem?.toggle(false);
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    font-family: Lexend;

    .van-collapse-item {
        background-color: $black-98-l;
        border-radius: 0.16rem;
        overflow: hidden;
    }

    .van-cell {
        padding: 0;
        display: flex;
        align-items: center;
        background-color: transparent;

        &__title {
            width: 84%;
            font-size: 0.16rem;
            font-weight: 700;
            line-height: 1.65;

            .self-title {
                padding: 0.16rem;
                color: $black-15-l;
            }
        }

        &::after {
            border-bottom: none;
        }

        .icon-right {
            width: 0.2rem;
            height: 0.2rem;
            transform: rotate(0);
            transition: transform 0.3s;
            background-image: url("../img/right.svg");
            background-size: cover;
            background-repeat: no-repeat;
            margin-right: 0.16rem;
        }
    }

    .van-cell--clickable:active {
        background-color: $black-98-l;
    }

    .van-collapse-item__title--expanded {
        .icon-right {
            transform: rotate(-90deg);
        }
    }

    .van-collapse-item--border {
        &::after {
            left: 0;
            right: 0;
            border-top: 1px solid $black-98-l;
        }
    }

    .van-collapse-item__wrapper {
        border-radius: 0 0 0.16rem 0.16rem;

        .van-collapse-item__content {
            padding: 0;
            color: $black-60-l;
            font-size: 0.12rem;
            font-weight: 400;
            background-color: transparent;
        }
    }
}

.finish {
    ::v-deep {
        .van-collapse-item {
            background-color: $green-80-l;
        }

        .van-cell {
            background-color: $green-80-l;

            &__title {
                font-size: 0.14rem;
                font-weight: 400;

                .self-title {
                    padding-bottom: 0.12rem;
                }
            }
        }

        .van-collapse-item__title--expanded {
            .icon-right {
                transform: rotate(0deg);
            }
        }

        .van-collapse-item__wrapper {
            .van-collapse-item__content {
                .finish-box {
                    width: 100%;
                    padding: 0 0.16rem 0.16rem;
                    background-color: $green-80-l;
                    word-break: break-word;

                    .finish-value {
                        color: $black-15-l;
                        font-size: 0.16rem;
                        font-weight: 700;
                        line-height: 165%;
                    }

                    .finish-error {
                        padding: 0.04rem 0;
                        color: $pink-40-d;
                        font-size: 0.12rem;
                        font-weight: 400;
                        line-height: 145%;
                    }
                }
            }
        }
    }
}

.input-item {
    padding-top: 0;

    ::v-deep {
        .input-box {
            font-family: Lexend !important;
            padding: 0.16rem;
            border: none;
            background: $black-98-l;

            .label {
                display: none;
            }

            &.focus {
                border: none;
            }

            .input-wrap {
                border-bottom: 1px solid $black-45-l;
            }

            .input-style {
                color: $base-black !important;
                font-family: Lexend !important;
                min-height: 0.26rem !important;
                font-weight: 700 !important;
                font-size: 0.16rem !important;
                line-height: 0.26rem !important;
                caret-color: $primary-purple !important;

                .placeholder,
                &::placeholder {
                    font-size: 0.14rem !important;
                    color: $black-98-l !important;
                    line-height: 0.23rem !important;
                }
            }

            .before {
                font-size: 0.16rem;
                font-weight: 700;
                color: $black-60-l;
                line-height: 1.65;
                display: flex;
                align-items: center;

                .before-dot {
                    display: inline-block;
                    width: 0.04rem;
                    height: 0.04rem;
                    margin-right: 0.1rem;
                    border-radius: 50%;
                    background-color: $black-75-l;
                    flex-shrink: 0;
                }
            }

            .after {
                height: 0.26rem;
                display: flex;
                align-items: center;

                .arrow {
                    border-bottom: 1.5px solid $black-45-l;
                    border-right: 1.5px solid $black-45-l;
                }
            }
        }
    }
}

.disabled-select {
    ::v-deep {
        .input-box {
            .input-style {
                color: $black-60-l !important;
            }
        }
    }
}

.clearable {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 0.02rem;
    flex-shrink: 0;
}
</style>
