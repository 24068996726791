<!-- @Author: wuhuiwang -->
<!-- @Date: 2022-11-18 18:55:11 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-05-31 13:52:28 -->

<template>
    <ec-overlay
        class="download-modal"
        :visible="visible"
    >
        <div class="overlay-wrapper">
            <div
                class="close"
                @click="$emit('close')"
            >
                <img src="./img/close.svg">
            </div>
            <div class="context-title download-title">
                {{ $t('borrow.download.title') }}
            </div>
            <div class="download-content">
                <div class="row content-row">
                    <div class="col-12 content-col">
                        <div>
                            <img
                                class="logo"
                                src="./img/google-logo.png"
                            >
                        </div>
                        <div class="code">
                            <img src="./img/google-code.png">
                        </div>
                        <div
                            class="store"
                            @click="handleDownload('google')"
                        >
                            <img
                                class="website"
                                src="./img/website.png"
                            >
                            <img
                                class="store-logo"
                                src="./img/google-store.png"
                            >
                        </div>
                    </div>
                    <div class="col-12 content-col">
                        <div>
                            <img
                                class="logo"
                                src="./img/app-logo.png"
                            >
                        </div>
                        <div class="code">
                            <img src="./img/app-code.png">
                        </div>
                        <div
                            class="store"
                            @click="handleDownload('apple')"
                        >
                            <img
                                class="website"
                                src="./img/website.png"
                            >
                            <img
                                class="store-logo"
                                src="./img/app-store.png"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ec-overlay>
</template>

<script>
import {downloadUrl, appleDownloadUrl} from 'credmex-new/common/constant/config';

import EcOverlay from 'easycash/common/component/ec-overlay';

export default {
    name: 'DownloadModal',

    components: {
        EcOverlay
    },

    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },

    methods: {
        handleDownload(type) {
            const url = type === 'google' ? downloadUrl : appleDownloadUrl;
            window.open(url);
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.download-modal {
    z-index: 99;

    .overlay-wrapper {
        position: relative;
        box-sizing: border-box;
        width: 800px;
        padding: 50px 0 30px;
        background-color: #fff;
        color: #2b4057;
        border-radius: 20px;
        text-align: center;

        .close {
            position: absolute;
            cursor: pointer;
            top: 26px;
            right: 34px;
        }

        .download-title {
            font-size: 32px;
            line-height: 52px;
            padding: 0 85px;
            margin-bottom: 50px;
        }

        .download-content {
            .content-row {
                width: 100%;
                margin: 0;
            }

            .content-col {
                padding: 14px 50px 20px;
                border-right: 1px solid #c0c0c0;
                display: flex;
                flex-direction: column;
                align-items: center;

                &:last-child {
                    border-right: none;
                }

                .logo {
                    width: 160px;
                    height: 44px;
                }

                .code {
                    width: 200px;
                    margin: 20px 0 44px;
                }

                .store {
                    width: 300px;
                    height: 60px;
                    background: #2b4057;
                    border-radius: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    .website {
                        width: 21px;
                        margin-right: 12px;
                    }

                    &-logo {
                        width: 98px;
                    }
                }
            }
        }

        @media screen and (max-width: $screen-md) {
            width: 80vw;
            padding: 30px 0;

            .close {
                top: 13px;
                right: 17px;

                img {
                    width: 14px;
                }
            }

            .download-title {
                font-size: 16px;
                line-height: 1.35;
                padding: 0 25px;
                margin-bottom: 30px;
            }

            .download-content {
                .row {
                    width: 100%;
                    margin: 0;
                }

                .content-col {
                    width: 50%;
                    padding: 0;

                    .logo {
                        width: 60%;
                        height: auto;
                        margin: 0 auto 3px;
                    }

                    .code {
                        width: 74%;
                        margin: 8px 0 15px;
                    }

                    .store {
                        width: 80%;
                        height: 30px;
                        border-radius: 15px;

                        .website {
                            width: 10px;
                            margin-right: 6px;
                        }

                        &-logo {
                            width: 50px;
                        }
                    }
                }
            }
        }
    }
}
</style>
