<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-3 16:48:43 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-11-06 13:06:52 -->

<template>
    <div class="empty">
        <img
            class="empty-img"
            src="../img/empty.png"
        >
        <div class="empty-text">
            {{ emptyText }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'CmEmpty',

    props: {
        emptyText: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
.empty {
    margin-top: 22vh;
    padding: 0 0.4rem;

    &-img {
        display: block;
        margin: 0 auto;
        width: 0.8rem;
    }

    &-text {
        margin: 0.12rem 0;
        text-align: center;
        color: $base-black;
        text-align: center;
        font-family: Lexend;
        font-size: 0.14rem;
        font-style: normal;
        font-weight: 400;
        line-height: 165%;
    }
}
</style>
