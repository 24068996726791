/*
 * @Author: ruiwang
 * @Date: 2019-12-18 15:21:16
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-09-21 13:19:41
 */

import VueI18n from 'vue-i18n';

import Vue from 'ssr-common/vue';

import EN from './languages/en.json';
import ES from './languages/es.json';

Vue.use(VueI18n);

export const createI18n = context => new VueI18n({
    locale: context.acceptLanguage,
    fallbackLocale: 'es',
    messages: {
        es: ES,
        en: EN
    },
    silentTranslationWarn: true
});
