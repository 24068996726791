<!--
 * @Author: zhaoyang
 * @Date: 2023-03-07 17:06:40
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-05-08 16:14:32
-->

<template>
    <div class="input-item">
        <div
            class="input-box"
            :class="{
                focus: isFocus,
                error: errorTip,
                noBorder: hasborder
            }"
        >
            <div
                v-if="labelActive"
                class="label"
                :class="{
                    'focus-color': isFocus,
                    'error-color': errorTip
                }"
                :style="{backgroundColor: labelBg}"
            >
                {{ $t(label) }}
            </div>
            <div class="input-wrap">
                <div
                    class="before"
                >
                    <slot
                        v-if="showBefore"
                        name="before"
                    />
                </div>

                <textarea
                    v-if="type==='textarea'"
                    id="input"
                    ref="input"
                    v-bind="$attrs"
                    class="input-style textarea-style"
                    :style="{
                        resize: autoResizeTextarea ? 'none' : 'vertical'
                    }"
                    :class="{'input-style-error': errorTip}"
                    :placeholder="labelActive ? '' : $t(label)"
                    :rows="rows"
                    :disabled="disabled"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="onInput"
                />
                <template v-else-if="type === 'select'||type === 'cascader'">
                    <div
                        class="input-style"
                        @click="onClick"
                    >
                        <span
                            v-if="!labelActive"
                            class="placeholder"
                        >
                            {{ $t(label) }}
                        </span>
                        <span v-else>{{ pickerLabel }}</span>
                    </div>
                    <van-popup
                        v-model="showPopup"
                        :close-on-click-overlay="false"
                        position="bottom"
                    >
                        <template v-if="!$slots['popup']">
                            <van-picker
                                v-if="type === 'select'"
                                ref="picker"
                                show-toolbar
                                :confirm-button-text="$t('webview.form.confirm')"
                                :cancel-button-text="$t('webview.form.cancel')"
                                v-bind="contentProps"
                                value-key="label"
                                :columns="columnsData"
                                @confirm="onConfirm"
                                @cancel="onCancel"
                            />
                            <van-cascader
                                v-if="type === 'cascader'"
                                v-model="cascaderValue"
                                :options="columnsData"
                                :placeholder="$t('webview.form.select')"
                                active-color="#03DA8B"
                                v-bind="contentProps"
                                @close="onCancel"
                                @change="onChange"
                                @finish="onFinish"
                            />
                        </template>
                        <slot
                            v-else
                            name="popup"
                        />
                    </van-popup>
                </template>
                <input
                    v-else
                    id="input"
                    ref="input"
                    class="input-style"
                    v-bind="$attrs"
                    :class="{'input-style-error': errorTip}"
                    :placeholder="labelActive ? '' : $t(label)"
                    :type="type"
                    :disabled="disabled"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="onInput"
                    @keyup.enter="onEnter"
                >
                <div class="after">
                    <slot name="after">
                        <div
                            v-if="type==='select'"
                            class="arrow"
                        />
                    </slot>
                </div>
            </div>
        </div>
        <div
            class="tip"
            :class="{
                'focus-color': isFocus,
                'error-color': errorTip
            }"
        >
            {{ errorTip || tip }}
        </div>
    </div>
</template>

<script>
import Cleave from 'cleave.js';
import {debounce, upperFirst} from 'lodash';
import {Popup, Picker, Cascader} from 'vant';

export default {
    name: 'InputItem',

    components: {
        [Popup.name]: Popup,
        [Picker.name]: Picker,
        [Cascader.name]: Cascader
    },

    inject: ['uploadClickEvent'],

    props: {
        contentProps: {
            type: Object,
            default: () => ({})
        },
        hasborder: {
            type: Boolean
        },
        rows: {
            type: Number,
            default: 1
        },
        isFitAndroid: {
            type: Boolean,
            default: false
        },
        field: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        labelBg: {
            type: String,
            default: '#F5FBFB'
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: [Array, String, Number],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tip: {
            type: String,
            default: ''
        },
        useCleave: {
            type: Object,
            default: null
        },
        raw: {
            type: Boolean,
            default: true
        },
        format: {
            type: Object,
            default: () => ({})
        },
        rules: {
            type: Array,
            default: () => ([])
        },
        autoResizeTextarea: {
            type: Boolean,
            default: false
        },
        focusShowBefore: {
            type: Boolean,
            default: false
        },
        columns: {
            type: [Object, Function],
            default: () => ({})
        },
        required: {
            type: Boolean,
            default: false
        },
        eventTrack: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const {value, formatVal} = this;
        const formatedVal = formatVal(value);

        return {
            cascaderValue: '',
            columnsData: [],
            showPopup: false,
            isFocus: false,
            cleave: null,
            formatedVal,
            errorTip: '',
            errorInfo: [],
            isChange: false,
            pickerLabel: value || '',
            hasInputed: false
        };
    },

    computed: {
        labelActive() {
            const {isFocus, value} = this;

            return isFocus || value;
        },

        showBefore() {
            const {
                isFocus,
                focusShowBefore
            } = this;

            if (!focusShowBefore) return true;

            return isFocus;
        }
    },

    watch: {
        value(val) {
            const {$refs: {input}, useCleave, formatVal, raw, cleave} = this;
            if (useCleave) {
                if (raw) {
                    if (val === cleave.getRawValue()) return;
                    cleave.setRawValue(val);
                } else {
                    if (val === cleave.getFormattedValue()) return;
                    input.value = val;
                }
            } else {
                if (!input || input.value === val) return;
                input.value = formatVal(val);
            }
        },

        columns: {
            handler(val) {
                this.getColumnsData();
            },
            deep: true
        }
    },

    mounted() {
        const {
            useCleave,
            $refs: {input},
            onValueChanged,
            formatedVal
        } = this;

        if (useCleave) {
            this.cleave = new Cleave(input, {
                ...useCleave,
                onValueChanged
            });
            this.cleave.setRawValue(formatedVal);
        } else if (input) {
            input.value = formatedVal;
            this.$emit('input', formatedVal);
        }

        if (this.type === 'textarea' && this.autoResizeTextarea && this.formatedVal) {
            this.autoResizeTextareaFn();
        }

        if (this.columns.query || typeof this.columns === 'function') {
            this.getColumnsData();
        }

        this.validateVal(formatedVal, true);

        this.$emit('mounted');
    },

    methods: {
        eventTrackFn: debounce(function (val) {
            const {eventTrack, field} = this;
            if (eventTrack) {
                this.uploadClickEvent(`${upperFirst(field)}Input`, {value: val});
            }
        }, 500),

        onConfirm(item) {
            let value;
            let label;
            if (Array.isArray(item)) {
                const values = this.$refs.picker.getValues();
                value = values.map(pickItem => pickItem.value);
                label = values.map(pickItem => pickItem.label).join(' ');
            } else {
                value = item.value;
                label = item.label;
            }

            this.pickerLabel = label;
            this.validateVal(value);
            this.$emit('input', value);
            this.eventTrackFn(value);
            this.showPopup = false;
            this.isFocus = false;
        },

        searchTree(data, value, callback) {
            data.forEach((item, index) => {
                if (item.value === value) {
                    return callback(item, index);
                }

                if (item.children) {
                    return this.searchTree(item.children, value, callback);
                }

                return null;
            });
        },

        async onChange({tabIndex, value}) {
            if (tabIndex === 3) return;
            const {columns} = this;
            const {data: {body}} = await columns.query({params: {pid: value}});
            this.searchTree(this.columnsData, value, item => {
                item.children = tabIndex === 2 ? body : columns.parse(body);
            });
        },

        onFinish({selectedOptions}) {
            this.showPopup = false;
            this.isFocus = false;
            const value = selectedOptions.map(option => option.label).join(',');
            this.pickerLabel = value;
            this.validateVal(value);
            this.$emit('input', value);
            this.eventTrackFn(value);
        },

        onCancel() {
            this.showPopup = false;
            this.isFocus = false;
            this.validateVal(this.value);
        },

        async getColumnsData() {
            const {columns} = this;
            let result = [];
            if (typeof columns === 'function') {
                this.columnsData = columns(this);

                return;
            }

            switch (typeof columns.query) {
            case 'string': {
                const enumMap = this.$t(columns.query);
                result = Object.keys(enumMap).map(key => ({label: enumMap[key], value: key}));
                break;
            }

            case 'function': {
                const {data: {body}} = await columns.query();
                result = body;
                break;
            }

            default: {
                break;
            }
            }

            this.columnsData = columns.parse ? columns.parse(result) : result;
        },
        onClick() {
            if (this.disabled) {
                return;
            }

            this.showPopup = true;
            this.hasInputed = true;
            this.isFocus = !this.isFocus;
        },
        autoResizeTextareaFn() {
            const textarea = this.$refs.input;
            textarea.style.height = `${textarea.scrollTop + textarea.scrollHeight }px`;
        },

        validateNoInputVal() {
            this.hasInputed = true;
            this.validateVal(this.formatedVal);
        },

        validateVal(value, isMounted) {
            const {hasInputed, required, field} = this;
            if (!hasInputed && required) {
                if (!value) {
                    this.$emit('validateChange', {
                        field,
                        isError: true,
                        errorInfo: [this.$t('webview.form.required')]
                    });
                }

                return;
            }

            if (isMounted) return;
            const rules = required ? [
                {
                    validator: val => !!val,
                    message: this.$t('webview.form.required')
                },
                ...this.rules
            ] : this.rules;

            if (rules.length) {
                let tip = '';
                const errorInfo = [];
                rules.forEach(({validator, message}) => {
                    if (!validator(value)) {
                        const msg = this.$t(message);
                        errorInfo.push(msg);
                        if (!tip.includes(msg)) tip += ` ${msg}`;
                    }
                });

                this.$emit('validateChange', {
                    field,
                    isError: !!tip,
                    errorInfo
                });

                this.errorInfo = errorInfo;
                this.errorTip = tip;
            }
        },

        formatVal(val) {
            if (this.format?.format) {
                return this.format.format(val);
            }

            return val;
        },

        onValueChanged({target: {rawValue, value}}) {
            const {raw, formatVal} = this;
            const formatedVal = formatVal(value);
            const formatedRawVal = formatVal(rawValue);
            const val = raw ? formatedRawVal : formatedVal;
            this.$emit('input', val);
            this.eventTrackFn(val);
            if (this.isChange) {
                this.validateVal(formatedRawVal);
            }

            this.isChange = true;
            if (rawValue !== formatedRawVal) {
                this.cleave.setRawValue(formatedRawVal);
            }
        },

        onInput(event) {
            this.hasInputed = true;
            if (!this.useCleave) {
                const value = this.formatVal(event.target.value);
                event.target.value = value;
                this.$emit('input', value);
                this.eventTrackFn(value);
                this.validateVal(value);
            }

            if (this.type === 'textarea' && this.autoResizeTextarea) {
                this.autoResizeTextareaFn(event);
            }
        },

        onFocus(evt) {
            this.isFocus = true;
            if (this.$listeners.focus) {
                this.$listeners.focus(evt);
            }
        },

        onBlur(evt) {
            this.isFocus = false;
            if (this.$listeners.blur) {
                this.$listeners.blur(evt);
            }
        },

        onEnter(evt) {
            this.$refs.input.blur();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~easycash/common/style/variables/input.scss";

.input-item {
    box-sizing: border-box;
    padding-top: 0.1rem;

    .focus-color {
        color: $focusColor !important;
    }

    .error-color {
        color: $errorColor !important;
    }

    .input-box {
        box-sizing: border-box;
        padding: 0.14rem;
        border-radius: 8px;
        border: 1px solid $initColor;
        position: relative;

        .label {
            position: absolute;
            left: 0.1rem;
            top: -0.1rem;
            box-sizing: border-box;
            padding: 0 0.05rem;
            font-size: 12px;
            font-family: Helvetica;
            color: $initColor;
            line-height: 16px;
        }

        .input-wrap {
            display: flex;
            align-items: center;

            .input-style {
                min-height: 0.18rem;
                font-size: 14px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: $initFontColor;
                line-height: 0.18rem;
                flex: 1;
                background-color: transparent;
                padding: 0;
                border: 0;
                outline: 0;

                .placeholder {
                    font-size: 12px;
                    font-family: Helvetica;
                    color: $initColor;
                    line-height: 16px;
                }
            }

            .after {
                .arrow {
                    width: 0.06rem;
                    height: 0.06rem;
                    border-bottom: 2px solid #ccc;
                    border-right: 2px solid #ccc;
                    transform: rotate(45deg);
                    margin-bottom: 0.05rem;
                }
            }

            .textarea-style {
                height: unset;
                min-height: 0.18rem;
            }

            #input::placeholder {
                font-size: 12px;
                font-family: Helvetica;
                color: $initColor;
                line-height: 0.16rem;
                font-weight: 400;
            }

            #input:disabled {
                color: $disableColor;
                opacity: 1;
                -webkit-text-fill-color: $disableColor;
            }

            .input-style-error {
                color: $errorColor;
            }
        }
    }

    .focus {
        border: 1px solid $focusColor;
    }

    .error {
        border: 1px solid $errorColor;
    }

    .noBorder {
        border: none;
    }

    .tip {
        color: $initColor;
        font-size: 12px;
        font-family: Helvetica;
        line-height: 16px;
        box-sizing: border-box;
        padding-left: 0.15rem;
    }
}
</style>
