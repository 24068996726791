<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-12-01 10:53:37.224 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-12 16:20:02 -->

<template>
    <div id="app">
        <router-view />
        <ec-webview-loading-circle v-show="!!reqCount" />
    </div>
</template>

<script type="babel/text">
import {Toast} from 'vant';

import {genWebDeviceToken} from 'ssr-common/util/browser-util';
import Vue from 'ssr-common/vue';
import interceptor from 'ssr-common/vue/mixin/interceptor';
import platformType from 'ssr-common/vue/mixin/platform-type';
import Vconsole from 'ssr-common/vue/mixin/vconsole';
import http from 'ssr-common/vue/vue-http';

import component from 'credmex-new/common/component';
import directive from 'credmex-new/common/directive';

import EcWebviewLoadingCircle from 'easycash/common/component/ec-webview-loading-circle';
import uploadEvent from 'easycash/common/mixin/upload-event';

const {common} = http.defaults.headers;
common['YQG-PLATFORM-SDK-TYPE'] = 'MEX_YQD';
common['YQG-PLATFORM-DEVICE-TOKEN'] = genWebDeviceToken();

Vue.use(component);
Vue.use(directive);

export default {
    name: 'Credmex',

    components: {
        // eslint-disable-next-line vue/no-unused-components
        Toast,
        EcWebviewLoadingCircle
    },

    mixins: [Vconsole(Vue), interceptor, platformType, uploadEvent],

    created() {
        Vue.prototype.$app = this;
    }
};
</script>

<style lang="scss">
.webview-loading-circle {
    .circular {
        position: absolute;
    }
}
</style>
